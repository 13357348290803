<script>
import {/*required,*/ email, requiredIf, sameAs } from "vuelidate/lib/validators";
import { authService } from "@/services/auth/authentication.service";
import { toastUtils } from "@/utils/toast.utils";

import {
  authMethods,
  authFackMethods,
  notificationMethods
} from "@/state/helpers";
import router from '../../../router';

export default {
  data() {
    return {
      username: "",
      password: "",
      newpass: '',
      confirmpass: '',
      isLoggin: false,
      isChecked: false,
      isSubmited: false,
      isSubmitedNewPass: false,
      userInfo: {},
      changingPass: false

    };
  },
  computed: {
    notification() {
      return this.$store ? this.$store.state.notification : null;
    }
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  validations: {
    username: {
      required: requiredIf(function () {
        return this.isSubmited;
      })
    },
    password: {
      required: requiredIf(function () {
        return this.isSubmited;
      })
    },
    newpass: {
      required: requiredIf(function () {
        return this.isSubmitedNewPass;
      })
    },
    confirmpass: {
      sameAsPassword: sameAs('newpass')
    }
  },
  mounted() {
  },

  methods: {
    ...authMethods,
    ...authFackMethods,
    ...notificationMethods,
    // Try to log the user in with the username
    // and password they provided.
    async tryToLogIn() {
      // stop here if form is invalid
      this.$v.$touch();
      this.isSubmited = true;
      if (this.$v.$invalid) {
        return;
      } else {
        this.isLoggin = true;

        const { username, password } = this;
        if (email && password) {
          const response = await authService.login({ username, password });
          this.isLoggin = false;
          this.isSubmited = false;
          if (response >= 400) {
            if (response == 500) {
              toastUtils.error(this.$bvToast, 'Connexion', 'Une erreur s\'est produite. Veuillez réssayer');
            } else {
              toastUtils.error(this.$bvToast, 'Connexion', 'Echèc de la connexion. \n Le nom d\'utilisateur ou le mot de passe est incorrect');
            }
          } else {
            this.$store.commit('setUserInfo', response.data);
           
              // if (this.isChecked) {
              //   authService.setRemenberMe(this.username, this.password, this.isChecked);
              // } else {
              //   authService.deleteRememberMe();
              // }
              await router.push({ name: "home" });
            
          }
        }
      }
    },
  }
};
</script>

<template>
  <div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="authentication-page-content p-4 d-flex align-items-center min-vh-100">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/images/logo-light.png" height="120" alt="logo" />
                          </a>
                        </div>
                      </div>

                      <b-alert variant="danger" class="mt-3" v-if="notification.message" show
                        dismissible>{{ notification.message }}</b-alert>

                      <div class="p-2 mt-5" v-if="!changingPass">
                        <form class="form-horizontal" @submit.prevent="tryToLogIn">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-user-fill auti-custom-input-icon"></i>
                            <label for="email">Nom d'utilisateur</label>
                            <input type="text" v-model="username" class="form-control" id="email"
                              placeholder="Entrer votre nom d'utilisateur"
                              :class="{ 'is-invalid': isSubmited && $v.username.$error }" />
                            <div v-if="$v.username.$error" class="invalid-feedback">
                              <span v-if="!$v.username.required">Le nom d'utilisateur est requis.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="userpassword">Mot de passe</label>
                            <input v-model="password" type="password" class="form-control" id="userpassword"
                              placeholder="Entrer votre mot de passe"
                              :class="{ 'is-invalid': isSubmited && $v.password.$error }" />
                            <div v-if="!$v.password.required" class="invalid-feedback">Le mot de passe est requis.</div>
                          </div>

                          <!-- <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                              v-model = "isChecked"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Se souvenir de moi</label>
                          </div> -->

                          <div class="mt-4 text-center">
                            <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Connexion
                              <b-spinner v-if="isLoggin" variant="light" small role="status"></b-spinner>
                            </button>
                          </div>

                          <!-- <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Mot de passe oublié?
                            </router-link>
                          </div> -->
                        </form>
                      </div>

                      <div class="p-2 mt-5" v-if="changingPass">
                        <h4 class="text-center mb-3">Saisissez un nouveau mote de passe !</h4>
                        <form class="form-horizontal" @submit.prevent="setNewPass">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="newpass">Nouveau mot de passe</label>
                            <input type="password" v-model="newpass" class="form-control" id="newpass"
                              placeholder="Entrer un nouveau mot de passe"
                              :class="{ 'is-invalid': isSubmitedNewPass && $v.newpass.$error }" />
                            <div v-if="$v.newpass.$error" class="invalid-feedback">
                              <span v-if="!$v.newpass.required">Ce champ est requis.</span>
                            </div>
                          </div>

                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="confirmpass">Confirmer mot de passe</label>
                            <input v-model="confirmpass" type="password" class="form-control" id="confirmpass"
                              placeholder="Confirmer le nouveau mot de passe"
                              :class="{ 'is-invalid': isSubmitedNewPass && $v.confirmpass.$error }" />
                            <div v-if="!$v.confirmpass.$error" class="invalid-feedback">
                              <span>Le mot de passe doit être identique</span>
                            </div>
                          </div>

                          <!-- <div class="custom-control custom-checkbox">
                            <input
                              type="checkbox"
                              class="custom-control-input"
                              id="customControlInline"
                              v-model = "isChecked"
                            />
                            <label
                              class="custom-control-label"
                              for="customControlInline"
                            >Se souvenir de moi</label>
                          </div> -->

                          <div class="mt-4 text-center">
                            <button class="btn btn-primary w-md waves-effect waves-light" type="submit">Changer
                              <b-spinner v-if="isLoggin" variant="light" small role="status"></b-spinner>
                            </button>
                          </div>

                          <!-- <div class="mt-4 text-center">
                            <router-link tag="a" to="/forgot-password" class="text-muted">
                              <i class="mdi mdi-lock mr-1"></i> Mot de passe oublié?
                            </router-link>
                          </div> -->
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>
                          © {{ new Date().getFullYear() }} Radio Shalomat. Powered by Ok&Co
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div></template>
